<template>
	<div class="landing-page text-white">
		<img class="app-logo" src="@/assets/logo.png">
		
		<div class="subheading">
			<v-card class="access-code-form">
				<v-card-title class="title">
					<h1>PLIC Books Online Store</h1>
				</v-card-title>

				Please enter the access code you received from your school or contact them for your access code

				<v-card-text>
					<v-text-field
						variant="outlined"
						fluid
						v-model="accessCode"
						label="Access Code"
						class="access-code"
						name="accessCode"
						@keyup="accessCodeError = false"
						@keyup.enter="checkAccessCode" 
						:error-messages="accessCodeErrors"
					/>
					<v-btn color="primary" @click="checkAccessCode" :loading="accessCodeLoading">
						Go To Book
					</v-btn>
					<v-alert type="error" class="error" v-if="accessCodeError">
						Access code not found.  Please contact your school for the correct access code.
					</v-alert>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';

export default {
	name: 'LandingPage',
	setup() {
		return {
			v$: useVuelidate()
		};
	},
	validations: {
		accessCode: { required, minLength: minLength(6), maxLength: maxLength(6) }
	},
	data: () => ({
		accessCode: '',
		accessCodeLoading: false,
		accessCodeError: false
	}),
	computed: {
		accessCodeErrors() {
			const errors = [];
			if(this.v$.accessCode.$dirty) {
				!this.v$.accessCode.required.$invalid || errors.push('Access Code is required');
				!this.v$.accessCode.minLength.$invalid || errors.push('Access Code has too few character - must be exactly 6 characters');
				!this.v$.accessCode.maxLength.$invalid || errors.push('Access Code has too many character - must be exactly 6 characters');
			}

			return errors;
		}
	},
	methods: {
		checkAccessCode() {
			if(this.accessCodeLoading) {
				return;
			}

			this.v$.$touch();
			this.accessCodeError = false;
			if(!this.v$.$error) {
				this.accessCodeLoading = true;
				axios.get(`${import.meta.env.VITE_PLIC_BOOKS_HOST}/api/v1/yearbook-store?accessCode=${this.accessCode}`).then(response => {
					let urlString = response.data.url;
					this.accessCodeLoading = false;
					if(!urlString.includes(window.location.host)) {
						this.accessCodeError = true;
					} else {
						let url = new URL(urlString);
						this.$router.push(url.pathname);
					}
				}).catch(error => {
					console.warn(error);
					this.accessCodeLoading = false;
					this.accessCodeError = true;
				});
			}
		}
	},
	mounted: function() {
		this.$store.commit('setDrawer', {
			title: 'PLIC Books Store',
			logo: null,
			branding: null
		});
	}
};
</script>

<style scoped>
.landing-page {
	width: 100%;
	z-index: 1;
}

@media all and (orientation:portrait) {
	.app-logo {
		max-width: 90%;
	}
}

@media all and (orientation:landscape) {
	.app-logo {
		max-width: 30%;
		margin-top: 4em;
	}

	.landing-page {
		align-self: flex-start;
	}
}

.access-code-form {
	max-width: 40em;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1em;
}
.access-code-form .title {
	margin-top: 1em;
}
.access-code {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
}
.error {
	margin-top: 1em;
}
</style>